var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Caricamento in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { align: "right" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "button",
                                variant: "lisaweb",
                                size: "sm",
                                title: "Associa officina al sinistro",
                              },
                              on: { click: _vm.openAssociateModal },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "person-plus",
                                  "aria-hidden": "true",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.canVerb(_vm.resource, "store")
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "button",
                                    variant: "primary",
                                    size: "sm",
                                    title: "Crea",
                                  },
                                  on: { click: _vm.openCreateModal },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "plus-circle",
                                      "aria-hidden": "true",
                                    },
                                  }),
                                  _vm._v("\n            Crea"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {},
                    [
                      _c("claim-parties", {
                        ref: _vm.tableRef,
                        attrs: {
                          noCustom: "",
                          hasChecks: "",
                          filterOn: {
                            byRelations: ["byCorrespondence", "byAddressBook"],
                          },
                          fields: _vm.fields,
                          repository: _vm.repository,
                          resource: _vm.resource,
                          filterName: _vm.filterName,
                          onlyActions: ["edit"],
                          noInnerWidth: "",
                        },
                        on: { edit: _vm.openEditModal },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "lisaweb",
                            size: "sm",
                            title: "Disassocia officine dal sinistro",
                          },
                          on: { click: _vm.onRemove },
                        },
                        [_vm._v("Rimuovi")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.formLoaded
        ? _c(
            "div",
            [
              _c("create-claim-party-modal", {
                attrs: {
                  status_claimparty: _vm.status_claimparty,
                  beFormCreate: _vm.beCreateForm,
                  beRulesCreate: _vm.beRules,
                  title: _vm.title,
                },
                on: { save: _vm.onCreate, close: _vm.closeCreateModal },
                model: {
                  value: _vm.evt,
                  callback: function ($$v) {
                    _vm.evt = $$v
                  },
                  expression: "evt",
                },
              }),
              _vm._v(" "),
              _c("add-claim-party-modal", {
                attrs: {
                  status_claimparty: _vm.status_claimparty,
                  fields: _vm.fields,
                  claim_id: _vm.resourceId,
                },
                on: { save: _vm.addClaimParty },
              }),
              _vm._v(" "),
              _c("edit-claim-party-modal", {
                attrs: {
                  status_claimparty: _vm.status_claimparty,
                  beFormEdit: _vm.beEditForm,
                  beRulesEdit: _vm.beRules,
                  title: _vm.title,
                },
                on: { close: _vm.closeEditModal, update: _vm.onUpdate },
                model: {
                  value: _vm.evt,
                  callback: function ($$v) {
                    _vm.evt = $$v
                  },
                  expression: "evt",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }